import Card from "@/components/shared-ui/Card";
import * as Styled from "./styled";
import ContactCard from "@/components/shared-ui/ContactCard";
import useCompany from "@/contexts/CompanyContext";
import { useAuth0 } from "@auth0/auth0-react";
import WelcomeCard from "../WelcomeCards/WelcomeCard";
import TasksCard from "../TasksCard";
import HubHighlights from "../HubHighlights";

const DashboardCustomers = () => {
  const { user } = useAuth0();
  const { company_name, airtable_view, status } = useCompany();

  const isFullUser =
    user && user["https://customer.seccl.tech/roles"].includes("all_features");

  const isOnboarding = status === "Onboarding";
  const isLive = status === "Live";

  return (
    <>
      <Card
        gridColumn="1 / -1"
        noPadding
        featureBackground={{ full: true, gradient: "light2" }}
      >
        <WelcomeCard companyName={company_name} />
      </Card>

      {isLive && <HubHighlights />}

      {isFullUser && airtable_view && (
        <Card gridColumn="1 / -1" data-testid="tasksCard">
          <Styled.CardHeading>Task tracker</Styled.CardHeading>
          <TasksCard />
        </Card>
      )}

      {isOnboarding && <HubHighlights />}
      <ContactCard />
    </>
  );
};

export default DashboardCustomers;
