import * as Styled from "./styled";
import Logo from "@assets/seccl-logo.svg?react";
import FacebookLogo from "@assets/icons/facebook.svg?react";
import TwitterLogo from "@assets/icons/twitter.svg?react";
import LinkedinLogo from "@assets/icons/linkedin.svg?react";
import InstagramLogo from "@assets/icons/instagram.svg?react";
import BCorpLogo from "@assets/icons/b-corp-tagline.svg?react";
import ISOLogo from "@assets/icons/iso-certification-with-reg.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Styled.Footer>
      <Styled.FooterContent>
        <Styled.FooterLogo>
          <Link to="/">
            <Logo />
          </Link>
        </Styled.FooterLogo>
        <Styled.FooterLinks>
          <Styled.FooterNav>
            <h5>Legal</h5>
            <ul>
              <li>
                <Link to="https://seccl.tech/privacy/" target="_blank">
                  Privacy policy
                </Link>
              </li>

              <li>
                <Link to="https://seccl.tech/cookies/" target="_blank">
                  Cookie policy
                </Link>
              </li>
            </ul>
          </Styled.FooterNav>
          <Styled.FooterSocials>
            <Link to="https://twitter.com/SecclTech" target="_blank">
              <TwitterLogo />
            </Link>
            <Link to="https://www.facebook.com/seccltech/" target="_blank">
              <FacebookLogo />
            </Link>
            <Link
              to="https://www.linkedin.com/company/seccl-technology"
              target="_blank"
            >
              <LinkedinLogo />
            </Link>
            <Link to="https://www.instagram.com/seccltech/" target="_blank">
              <InstagramLogo />
            </Link>
          </Styled.FooterSocials>
        </Styled.FooterLinks>

        <Styled.FooterSmallPrint>
          <Styled.BCorpLogoWrapper>
            <BCorpLogo />
          </Styled.BCorpLogoWrapper>
          <Styled.ISOLogoWrapper>
            <img src={ISOLogo} alt="ISO certification logo" />
          </Styled.ISOLogoWrapper>
          <Styled.FooterColophon>
            Seccl Technology Limited is a company registered in England and
            Wales at 20 Manvers St, Bath, BA1 1JW (Number: 10237930). Seccl
            Custody Limited, a wholly owned subsidiary of Seccl Technology
            Limited, is registered in England and Wales (Number: 10430958), and
            authorised and regulated by the Financial Conduct Authority (Firm
            Reference Number: 793200). Copyright © 2023 Seccl Technology
            Limited
          </Styled.FooterColophon>
        </Styled.FooterSmallPrint>
      </Styled.FooterContent>
    </Styled.Footer>
  );
};

export default Footer;
