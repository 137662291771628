import { useMemo } from "react";
import useCompany from "@/contexts/CompanyContext";
import { primaryMenu } from "@/components/layout/Header/shared/nav.constants";
import { addCustomerSpecificLinks as generateHeaderNav } from "@/components/layout/Header/shared/nav.utils";
import { useAuth0 } from "@auth0/auth0-react";

const useHeaderNavigation = () => {
  const { companySpecificLinks, project_summary, status } = useCompany();
  const { user, isAuthenticated } = useAuth0();
  const projectSummaryLink =
    project_summary.length > 0 ? "/project-summary" : null;
  const userRoles = isAuthenticated && user && user["https://customer.seccl.tech/roles"];

  // Memoize headerNav, only recomputing when the data it relies on changes
  const headerNav = useMemo(() => {
    const navMenu = generateHeaderNav(
      companySpecificLinks,
      primaryMenu,
      projectSummaryLink,
      userRoles,
      status
    );

    return navMenu;
  }, [companySpecificLinks, projectSummaryLink, userRoles, status]);

  return headerNav;
};

export default useHeaderNavigation;
