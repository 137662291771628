import { CardHeading } from "@/pages/Dashboard/styled";
import * as Styled from "./styled";
import Button from "@/components/shared-ui/Button";
import TrainingHighlight from "@assets/training-highlight.png";
import TrainingHighlightFull from "@assets/training-highlight-full.png";
import { useMediaQuery } from "@/hooks/useMatchMedia";

const HubHighlights = () => {
  const isDesktop = useMediaQuery("medium");
  return (
    <Styled.HubHighlights gridColumn="1 / -1">
      <CardHeading>Some highlights from the hub</CardHeading>
      <Styled.CardWrapper>
        <Styled.HighlightCard
          featureBackground={{ full: true, gradient: "light2" }}
          gridColumn="1"
        >
          <div>
            <strong>Process documentation</strong>
            <p>
              Find detailed information on the operational and technical
              processes involved in launching and running a platform on Seccl’s
              technology.
            </p>
          </div>
          <Button
            data-testid="processDocsButton"
            label="See documents"
            variant="tertiary"
            internalLink="/docs"
          />
        </Styled.HighlightCard>
        <Styled.HighlightCard
          featureBackground={{ full: true, gradient: "light2" }}
          gridColumn={isDesktop ? "2" : "1"}
        >
          <div>
            <strong>System training</strong>
            <p>
              Bitesize training videos showing how to carry out key actions
              through our user interface
            </p>
          </div>
          <Button
            label="See training videos"
            variant="tertiary"
            internalLink="/system-training"
          />
        </Styled.HighlightCard>
      </Styled.CardWrapper>
      {isDesktop ? (
        <Styled.TrainingHighlightImagePartial
          src={TrainingHighlight}
          alt="Hub highlights - UI Training"
        />
      ) : (
        <Styled.TrainingHighlightImageFull
          src={TrainingHighlightFull}
          alt="Hub highlights - UI Training"
        />
      )}
    </Styled.HubHighlights>
  );
};

export default HubHighlights;
