import styled from "styled-components";
import { InlineImage } from "../ImageWithModal/styled";
import type { DirectusBlockCardVariant } from "@/services/api/types";

export const Diagram = styled(InlineImage)`
  cursor: auto;
`;

export const Card = styled.div<{ variant: DirectusBlockCardVariant }>`
  border-radius: 0.5rem;
  background: ${(props) =>
    props.variant === "gradient"
      ? props.theme.gradients.light2
      : props.theme.colors.lightest};
  padding: 1rem;
  flex-grow: 1;
  width: calc(50% - 1rem);
  ${(props) =>
    props.variant === "white" &&
    `
  border: 1px solid ${props.theme.colors.light};
  `}
  &&& h4 {
    text-transform: unset;
    margin-top: 0;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const VideoWrapper = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
  margin-bottom: 1.5rem;
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`;

export const DirectusTable = styled.div`
  font-weight: 300;
  li {
    line-height: 1.5rem;
  }
  ul {
    margin: 0;
  }
  li > ul,
  li > ol {
    margin-top: 0.5rem;
  }
  li + li {
    margin-top: 0.5rem;
  }
  li > ul li + li,
  li > ol li + li {
    margin-top: 0.5rem;
  }
  p {
    margin: 0 0 0.5rem;
    font-size: 0.875rem;
  }

  table {
    line-height: 1.25rem;

    td,
    th:last-of-type,
    td:last-of-type {
      padding: 1rem 1.7rem;
    }
  }
`;
