import styled, { css } from "styled-components";
import ChevronIcon from "@assets/icons/arrow-down.svg?react";
import UserProfileIcon from "@assets/icons/user-profile.svg?react";
import ExternalLinkIcon from "@assets/icons/external-link.svg?react";

export const ExpandedHeader = styled.div<{ isAdmin?: boolean }>`
  position: relative;
  display: flex;
  flex: 1;
  justify-content: ${(props) => (props.isAdmin ? "flex-end" : "space-between")};
`;

export const HeaderUtilitySection = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  svg {
    height: auto;
  }
`;

export const Heading = styled.h1`
  margin: 0;
`;

export const UserWidget = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
`;

export const UserProfile = styled(UserProfileIcon)`
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
  color: ${(props) => props.theme.colors.highlight2};
  display: inline-block;
`;

export const HeaderNavItem = styled.div`
  font-size: 0.875rem;
  cursor: pointer;
  padding-right: 1rem;
`;

export const Chevron = styled(ChevronIcon)`
  width: 0.5rem;
  height: 0.5rem;
  margin-left: 0.5rem;
  color: ${(props) => props.theme.colors.highlight2};
  display: inline-block;
`;

export const MobileNav = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  cursor: pointer;
`;

export const DropdownBase = styled.div<{
  open: boolean;
  placement?: string;
  direction?: string;
}>`
  cursor: auto;
  display: ${(props) => (props.open ? "flex" : "none")};
  position: absolute;
  background: ${(props) => props.theme.colors.lightest};
  color: ${(props) => props.theme.colors.dark};
  flex-direction: ${(props) => (props.direction ? props.direction : "row")};
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.shadows.medium};
  margin-top: 1rem;
  width: max-content;
  z-index: 7;
`;

export const Dropdown = styled(DropdownBase)<{
  open: boolean;
  placement?: string;
  direction?: string;
}>`
  padding: 0.25rem;
  top: 80%;
  left: 50%;
  transform: translateX(-65%);

  &::after {
    content: " ";
    position: absolute;
    margin-left: -0.5rem;
    border-style: solid;
    border-color: transparent transparent
      ${(props) => props.theme.colors.lightest} transparent;

    ${(props) =>
      props.placement === "Account management" &&
      css`
        top: -37px;
        right: 566px;
        border-width: 1.5rem 1rem;
      `}

    ${(props) =>
      (props.placement === "Operations" || props.placement === "Onboarding") &&
      css`
        top: -37px;
        right: 422px;
        border-width: 1.5rem 1rem;
      `}

      ${(props) =>
      props.placement === "Resources" &&
      css`
        top: -37px;
        right: 128px;
        border-width: 1.5rem 1rem;
      `}
  }
`;

export const UserDropdown = styled(DropdownBase)<{
  open: boolean;
  placement?: string;
  direction?: string;
}>`
  top: 85%;
  right: -5px;

  &::after {
    content: " ";
    position: absolute;
    margin-left: -0.5rem;
    border-style: solid;
    border-color: transparent transparent
      ${(props) => props.theme.colors.lightest} transparent;
    top: -22px;
    right: 11px;
    border-width: 0.75rem 0.6rem;
  }
`;

export const DropdownColumn = styled.div`
  max-width: 600px;
  margin: 0.875rem 0.875rem 0.875rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const DropdownSection = styled.div<{ $withBackground?: boolean }>`
  padding: 0 1.75rem 0.875rem 1.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
  ${(props) =>
    props.$withBackground &&
    css`
      background: ${props.theme.gradients.light2};
      margin: 0.875rem;
      padding: 0.875rem;
      border-radius: 4px;
    `}
`;

export const DropdownHeading = styled.span`
  display: block;
  font-size: 0.875rem;
  line-spacing: 1;
  color: ${(props) => props.theme.colors.accent1};
`;

export const DropdownLightText = styled.span`
  display: block;
  font-weight: 300;
`;

export const DropdownNavItemDetails = styled.div`
  max-width: 300px;
  line-height: 1.25rem;

  img {
    margin-top: 18px;
    margin-bottom: -14px;
    margin-right: -14px;
    padding-left: 90px;
  }
`;

export const DropdownItemIcon = styled.div`
  grid-area: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-right: 0.5rem;
    margin-bottom: 0.125rem;
    width: 0.829rem;
    height: 0.829rem;
  }
`;

export const DropdownItemTitle = styled.div`
  grid-area: 1 / 2;
  font-weight: 500;
`;

export const DropdownItemDescription = styled.div`
  grid-area: 2 / 2;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.medium};
`;

export const UserDropdownItem = styled.div`
  padding: 1rem 1.75rem;
  cursor: pointer;
  border-bottom: 1px solid ${(props) => props.theme.colors.light};
  &:first-of-type {
    border-top: 1px solid ${(props) => props.theme.colors.light};
    border-radius: 8px 8px 0 0;
  }
  &:last-child {
    border: none;
    border-radius: 0 0 8px 8px;
  }
  &:hover {
    background: ${(props) => props.theme.colors.light};
  }
`;

export const ExternalLink = styled(ExternalLinkIcon)`
  width: 0.625rem;
  margin-left: 0.35rem;
  display: inline-block;
  cursor: pointer;
`;
