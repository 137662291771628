import Page from "@/components/layout/Page";
import * as Styled from "./styled";
import { CardHeading } from "../Dashboard/styled";
import Card from "@/components/shared-ui/Card";
import FileDownloadButton from "@/components/utility/FileDownloadButton";
import useApi from "@/contexts/ApiContext";
import { useQuery } from "@tanstack/react-query";
import LoadingScreen from "@/components/shared-ui/LoadingScreen";
import config from "@/config";

const UsefulDocuments = () => {
  const api = useApi();

  const { data: sharedDocuments } = useQuery(["get-shared-documents"], () =>
    api.sharedDocuments.getAll()
  );

  if (!sharedDocuments) {
    return <LoadingScreen />;
  }
  return (
    <Page title="Useful documents" searchIndexPriority="resources">
      <Styled.UsefulDocuments>
        <Card>
          <CardHeading data-testid="usefulDocsHeader">
            Useful documents
          </CardHeading>
          <Styled.SharedDocGrid>
            {sharedDocuments.length < 1 && <p>No documents here yet.</p>}
            {sharedDocuments.map((doc) => (
              <Styled.SharedDoc key={doc.id}>
                <Styled.Thumbnail
                  src={`${config.DIRECTUS_URL}/assets/${doc.thumbnail}`}
                />
                <div>
                  <strong>{doc.title}</strong>
                  <FileDownloadButton
                    fileLink={{ link: doc.hubspot_url, type: "pdf" }}
                    buttonLabel="Download document"
                    makeParentClickable
                  />
                </div>
              </Styled.SharedDoc>
            ))}
          </Styled.SharedDocGrid>
        </Card>
      </Styled.UsefulDocuments>
    </Page>
  );
};

export default UsefulDocuments;
