import { css } from "styled-components";
import regularBrown from "@assets/fonts/brownstd/BrownLLWeb-Regular.woff2";
import boldBrown from "@assets/fonts/brownstd/BrownLLWeb-Bold.woff2";
import lightBrown from "@assets/fonts/brownstd/BrownLLWeb-Light.woff2";

export const fonts = css`
  /*
 * Legal Disclaimer for Web licence
 *
 * Licence to use self-hosted webfonts for displaying dynamic text on specified website domains. Our package includes WOFF and WOFF2 font formats.
 *
 * Should the license page views limit be exceeded the license holder will be required to contact Lineto.com for an upgrade.
 * 
 * It is strictly prohibited to rename the font and to download or use these fonts in any other media. 
 * 
 * These Web fonts are licensed exclusively for the use on the following domain(s) and their subdomains:
 *
 * seccl.tech (monthly pageviews: <50K)
 *
 */

  @font-face {
    font-family: BrownLLWeb;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src:
      local("Brown"),
      local("Brown-Bold"),
      url(${boldBrown}) format("woff2");
  }

  @font-face {
    font-family: BrownLLWeb;
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src:
      local("Brown"),
      local("Brown-Light"),
      url(${lightBrown}) format("woff2");
  }

  @font-face {
    font-family: BrownLLWeb;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
      local("Brown"),
      local("Brown-Regular"),
      url(${regularBrown}) format("woff2");
  }
`;
