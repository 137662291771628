import useApi from "@/contexts/ApiContext";
import { useQuery } from "@tanstack/react-query";
import * as Styled from "./styled";
import { Spinner } from "../../../components/shared-ui/LoadingScreen/styled";
import Button from "@/components/shared-ui/Button";
import ErrorScreen from "@/components/shared-ui/ErrorScreen";
import TasksTable from "@/pages/Tasks/TasksTable";

const TasksCard = () => {
  const api = useApi();

  const {
    data: tasks,
    isLoading,
    isError,
  } = useQuery(
    ["get-example-airtable-tasks"],
    () => api.airtableTask.getAll(false, 4),
    { useErrorBoundary: false } // We have a custom error for this (below)
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return <ErrorScreen withoutPage />;
  }

  if (!Array.isArray(tasks.rows)) {
    return "No tasks available right now.";
  }

  return (
    <Styled.TableWrapper data-testid="tasksTable">
      <Styled.TableFade>
        <TasksTable columns={tasks.fieldNames} data={tasks.rows} view="dashboard" />
      </Styled.TableFade>
      <Button variant="secondary" internalLink="/tasks" label="See all tasks" />
    </Styled.TableWrapper>
  );
};

export default TasksCard;
