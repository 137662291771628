import Configuration from "@assets/icons/config.svg?react";
import ServiceTargets from "@assets/icons/target.svg?react";
import KeyContacts from "@assets/icons/contacts.svg?react";
import CustomerSuccess from "@assets/icons/track-changes.svg?react";
import TransferTracker from "@assets/icons/transfer.svg?react";
import BugTracker from "@assets/icons/bug.svg?react";
import DocAttach from "@assets/icons/document-attach.svg?react";
import ProcessDocs from "@assets/icons/process.svg?react";
import UiTraining from "@assets/icons/training.svg?react";
import ApiTraining from "@assets/icons/api.svg?react";
import InFlightCoAx from "@assets/icons/data.svg?react";
import CompletedCoAx from "@assets/icons/data-historic.svg?react";
import Questionnaire from "@assets/icons/questionnaire.svg?react";
import TaskTracker from "@assets/icons/circle-check.svg?react";

export type UserRoles = "sales_demo" | "basic" | "all_features";
export type CompanyStatus = "Live" | "Onboarding" | "Prospecting";

export type NavMenu = {
  title: string;
  description?: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  to?: string;
  secondary?: NavMenu[];
  hidden?: boolean;
  roleGuard?: UserRoles;
  statusGuard?: CompanyStatus;
  external?: boolean;
  airtableLabel?: string;
};

export type ImmutableNavMenu = ReadonlyArray<NavMenu>;

const accountManagementMenu: NavMenu[] = [
  {
    title: "Configuration",
    description: "Details of your platform configuration and proposition",
    icon: Configuration,
    to: "/configuration",
    roleGuard: "all_features",
  },
  {
    title: "Service targets",
    description: "Data on our performance against agreed service targets",
    icon: ServiceTargets,
    to: "/service-targets",
    roleGuard: "all_features",
    statusGuard: "Live",
  },
  {
    title: "Account management structure",
    description: "Key contacts and escalation points within Seccl",
    icon: KeyContacts,
    to: "/account-management",
    roleGuard: "all_features",
  },
  {
    title: "Your documents",
    description:
      "See recent governance packs and important contract-related docs",
    icon: DocAttach,
    external: true,
    roleGuard: "all_features",
    airtableLabel: "Your documents",
  },
  {
    title: "Task tracker",
    description:
      "Keep on top of outstanding tasks with your very own Seccl 'to do' list",
    icon: TaskTracker,
    to: "/tasks",
    roleGuard: "all_features",
  },
  {
    title: "Project summary",
    description:
      "All the info relating to your platform launch project, in one place",
    icon: Questionnaire,
    roleGuard: "all_features",
    statusGuard: "Onboarding",
  },
];

const operationsMenu: NavMenu[] = [
  {
    title: "Customer success queries",
    description:
      "Access Zendesk, our customer success and query management system",
    icon: CustomerSuccess,
    to: "https://support.seccl.tech/hc/en-gb",
    external: true,
  },
  {
    title: "Bug tracker",
    description:
      "See all known bugs that are relevant to you (or report new ones)",
    icon: BugTracker,
    hidden: true,
  },
  {
    title: "Asset onboarding tracker",
    description: "Track the status of your requested assets",
    icon: TransferTracker,
    external: true,
    airtableLabel: "Asset tracker",
  },
  {
    title: "Completed CoAx",
    description: "See historic data on all completed corporate actions",
    icon: CompletedCoAx,
    to: "https://airtable.com/appJvfuRMHbGlwmMA/shrfFmUFKCR6Ay2sQ/tbl7ajA0K2vLOeQnC",
    external: true,
  },
  {
    title: "In-flight CoAx",
    description: "See data on all corporate actions currently in progress",
    icon: InFlightCoAx,
    to: "https://airtable.com/appJvfuRMHbGlwmMA/shrFX2WHfd7b7O2Yc/tbl7ajA0K2vLOeQnC",
    external: true,
  },
  {
    title: "Pension due diligence",
    description:
      "Complete our questionnaire for sub-firms looking to launch a Seccl-powered pension",
    icon: Questionnaire,
    to: "https://airtable.com/appy1M389Gy6KKglw/shrlclnd1TSvAQL1p",
    external: true,
    roleGuard: "all_features",
  },
  {
    title: "ATG transfer tracker",
    description: "Check the status of all transfers made via ATG",
    icon: TransferTracker,
    external: true,
    airtableLabel: "ATG transfer tracker",
    roleGuard: "all_features",
  },
  {
    title: "Origo transfer tracker",
    description: "Check the status of all transfers made via Origo",
    icon: TransferTracker,
    external: true,
    airtableLabel: "Origo transfer tracker",
    roleGuard: "all_features",
  },
  {
    title: "Electronic transfers providers",
    description: "Information on available electronic transfer providers",
    to: "https://airtable.com/appJlbdG7AamuohW0/shrnz66ccrGJiZB3K",
    icon: TransferTracker,
    external: true,
  },
];

const onboardingMenu: NavMenu[] = [
  {
    title: "Asset onboarding tracker",
    description: "Track the status of your requested assets",
    icon: TransferTracker,
    external: true,
    airtableLabel: "Asset tracker",
  },
  {
    title: "Electronic transfers providers",
    description: "Information on available electronic transfer providers",
    to: "https://airtable.com/appJlbdG7AamuohW0/shrnz66ccrGJiZB3K",
    icon: TransferTracker,
    external: true,
  },
  {
    title: "Adviser SIPP DDQ",
    description:
      "Due diligence questionnaire to be completed by advice firms accessing a SIPP on your platform",
    to: "https://airtable.com/appy1M389Gy6KKglw/shrlclnd1TSvAQL1p",
    icon: Questionnaire,
    external: true,
  },
];

const resourcesMenu: NavMenu[] = [
  {
    title: "System training",
    description:
      "Training videos showing how to carry out key actions through our user interface",
    icon: UiTraining,
    to: "/system-training",
  },
  {
    title: "UI training",
    description:
      "Training videos showing how to carry out key actions through our user interface",
    icon: UiTraining,
    to: "/ui-training",
    hidden: true,
  },
  {
    title: "Due diligence",
    description: "Carry out DD on Seccl with our comprehensive FAQ library",
    icon: DocAttach,
    to: "/due-diligence",
  },
  {
    title: "API training",
    description:
      "Get to grips with our API docs with our detailed video walk-throughs",
    icon: ApiTraining,
    to: "/api-training",
    hidden: true,
  },
  {
    title: "Process docs",
    description:
      "Details on the operational and technical processes that power your platform",
    icon: ProcessDocs,
    to: "/docs",
  },
  {
    title: "Useful documents",
    description: "Check out our range of handy guides on how we work together",
    icon: DocAttach,
    to: "/useful-documents",
  },
];

export const primaryMenu: ImmutableNavMenu = Object.freeze([
  {
    title: "Account management",
    description:
      "Find key information, important documents and system links relevant to your relationship with Seccl",
    secondary: accountManagementMenu,
    roleGuard: "all_features",
  },
  {
    title: "Operations",
    description:
      "Useful links related to the operational workings of your platform (links are password protected for authorised users only)",
    secondary: operationsMenu,
    statusGuard: "Live",
  },
  {
    title: "Onboarding",
    description:
      "Useful links related to your platform setup, configuration, and operations.",
    secondary: onboardingMenu,
    statusGuard: "Onboarding",
  },
  { title: "Roadmap", to: "/product-roadmap" },
  { title: "News & updates", to: "/updates" },
  {
    title: "Resources",
    description:
      "Training videos and documents you need to help run your platform",
    secondary: resourcesMenu,
  },
]);
