import * as Styled from "./styled";
import ExclamationCircle from "@assets/icons/icon-changed.svg?react";
import MovedForward from "@assets/icons/icon-progressed.svg?react";
import PushedBack from "@assets/icons/icon-moved-back.svg?react";
import LeftBorderCard from "@/components/shared-ui/LeftBorderCard";

const RoadmapIntroduction = ({
  updateLink,
  title,
}: {
  updateLink: string;
  title: string;
}) => {
  return (
    <Styled.Introduction>
      <Styled.IntroSection hasBackground>
        <Styled.Heading>Product roadmap</Styled.Heading>
        <Styled.SubHeading>How to read this roadmap</Styled.SubHeading>
      </Styled.IntroSection>
      <Styled.IntroSection>
        <p>
          We've separated the roadmap by product area and capability, then
          listed the highest priority items we're working on which we'll deliver
          now, next and later.
        </p>
        <p>
          To prevent information overload, the roadmap doesn't contain all items
          on the backlog. Speak to your account manager for further backlog
          detail.
        </p>
        <p>
          Click on each card to see more detailed information about each item.
        </p>
      </Styled.IntroSection>
      <Styled.IntroSection>
        <p>
          Cards move between the now, next and later categories, or information
          and dates can change. Where there are changes to items, these will be
          indicated on cards with the following symbols:
        </p>
        <Styled.IconKey>
          <Styled.IconKeyRow>
            <Styled.IconContainer>
              <MovedForward />
            </Styled.IconContainer>
            <p>
              The item has progressed along the delivery chain into the next or
              now columns
            </p>
          </Styled.IconKeyRow>
          <Styled.IconKeyRow>
            <Styled.IconContainer>
              <PushedBack />
            </Styled.IconContainer>
            <p>
              The item has moved down the delivery chain into the next or later
              columns
            </p>
          </Styled.IconKeyRow>
          <Styled.IconKeyRow>
            <Styled.IconContainer>
              <ExclamationCircle />
            </Styled.IconContainer>
            <p>
              Details have changed. Click on the card to see what's changed and
              why
            </p>
          </Styled.IconKeyRow>
        </Styled.IconKey>
      </Styled.IntroSection>
      <Styled.RoadmapReview>
        <LeftBorderCard
          heading={title}
          content="A short commentary from our product team to explain roadmap movement, delivery and changes."
          internalLink={updateLink}
          buttonLabel="Read roadmap update"
        />
      </Styled.RoadmapReview>
    </Styled.Introduction>
  );
};

export default RoadmapIntroduction;
