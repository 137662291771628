import Page from "@/components/layout/Page";
import Logo from "@assets/seccl-logo.svg?react";
import * as Styled from "./styled";

type Props = {
  withoutPage?: boolean;
  topLevel?: boolean;
};

const ErrorScreen = ({ withoutPage, topLevel }: Props) => {
  const error = "Sorry something went wrong";
  if (topLevel) {
    return (
      <Styled.ErrorScreen>
        <Logo />
        <Styled.TopLevelErrorMessage>
          Sorry, something went wrong with your account configuration
        </Styled.TopLevelErrorMessage>
        <p>Please contact your Seccl contact and we will get this fixed.</p>
      </Styled.ErrorScreen>
    );
  }
  if (withoutPage) {
    return <Styled.ErrorMessage>{error}</Styled.ErrorMessage>;
  } else {
    return (
      <Page title="">
        <Styled.ErrorScreen>{error}</Styled.ErrorScreen>
      </Page>
    );
  }
};

export default ErrorScreen;
