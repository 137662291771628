import styled from "styled-components";
import { Card } from "@/components/shared-ui/Card/styled";

export const PlatformSetupCard = styled(Card)<{ gridColumn?: string }>`
  display: flex;
  flex-direction: column;
  grid-column: 1 / -1;
  box-shadow: none;
  border-left: 0.8rem solid ${(props) => props.theme.colors.dark};

  ${(props) => props.theme.media.medium} {
    grid-column: ${(props) => (props.gridColumn ? props.gridColumn : "1 / -1")};
  }
`;

export const CardWithImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.media.medium} {
    flex-direction: row;
  }
`;

export const CardWithImageContent = styled.div`
  width: 100%;
`;

export const PlatformSetupCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0;
  ${(props) => props.theme.media.medium} {
    flex-direction: row;
    align-items: center;
    gap: 1rem;
  }
`;

export const CardHeading = styled.h2`
  margin: 0;
  line-height: 1.75rem;
`;

export const CardImage = styled.img`
  border-radius: 8px;
  width: 100px;
  margin-bottom: 1.25rem;
  ${(props) => props.theme.media.medium} {
    margin-bottom: 0;
    margin-right: 1.25rem;
  }
`;
