import { useAuth0 } from "@auth0/auth0-react";
import * as Styled from "./styled";
import { Link } from "react-router-dom";
import { useState } from "react";
import EditProfile from "../EditProfile";
import Button from "@/components/shared-ui/Button";
import Search from "@/components/shared-ui/Search";
import type { SearchIndexTypes } from "@/components/shared-ui/Search/Search";
import { useMediaQuery } from "@/hooks/useMatchMedia";
import ExpandedMobileNav from "./ExpandedMobileNav";
import { toCamelCase } from "@/utils/helpers.utils";
import TrainingHighlight from "@assets/training-highlight.png";
import DynamicNavLink from "./DynamicNavLink";
import useClickOutside from "@/hooks/useClickOutside";
import useHeaderNavigation from "@/hooks/useHeaderNavigation";

type Props = {
  isAdmin?: boolean;
  searchIndexPriority?: SearchIndexTypes;
};

type NavDropdownState = {
  accountManagement: boolean;
  operations: boolean;
  onboarding: boolean;
  resources: boolean;
  userWidget: boolean;
  mobileNav: boolean;
};
type NavDropdownKey = keyof NavDropdownState;

const navDropdownInitialState = {
  accountManagement: false,
  operations: false,
  onboarding: false,
  resources: false,
  userWidget: false,
  mobileNav: false,
};

const ExpandedHeader = ({ isAdmin, searchIndexPriority }: Props) => {
  const { user, isAuthenticated, logout } = useAuth0();
  const headerNav = useHeaderNavigation();
  const { addElement } = useClickOutside(() => {
    setDropdownOpen(navDropdownInitialState); // Triggered when clicking outside all registered elements
  });
  const isDesktop = useMediaQuery("extraLarge");
  const [dropdownOpen, setDropdownOpen] = useState<NavDropdownState>(
    navDropdownInitialState
  );
  const [modalOpen, setModalOpen] = useState(false);

  const toggleDropdown = (key: NavDropdownKey) => {
    setDropdownOpen((prev) => {
      return {
        ...navDropdownInitialState,
        [key]: !prev[key],
      };
    });
  };

  const isAdminUser =
    user && user["https://customer.seccl.tech/roles"].includes("admin");

  return (
    <Styled.ExpandedHeader isAdmin={isAdmin} data-testid="expandedHeader">
      {!isAdmin ? (
        <Search indexPriority={searchIndexPriority} expanded />
      ) : null}
      {isDesktop ? (
        <Styled.HeaderUtilitySection>
          {!isAdmin ? (
            <>
              {isAuthenticated && user && (
                <>
                  {headerNav.map((item) => {
                    if (item.secondary) {
                      const camelCaseTitle = toCamelCase(item.title);
                      return (
                        <Styled.HeaderNavItem
                          key={item.title}
                          onClick={() => {
                            toggleDropdown(camelCaseTitle as NavDropdownKey);
                          }}
                          ref={(el) => addElement(el)}
                          data-testid={`${camelCaseTitle}NavDropdown`}
                        >
                          <strong>{item.title}</strong>
                          <Styled.Chevron />
                          <Styled.Dropdown
                            open={
                              dropdownOpen[camelCaseTitle as NavDropdownKey]
                            }
                            placement={item.title}
                          >
                            <div>
                              <Styled.DropdownNavItemDetails>
                                <Styled.DropdownSection $withBackground>
                                  <strong>{item.title}</strong>
                                  <Styled.DropdownLightText>
                                    {item.description}
                                  </Styled.DropdownLightText>
                                  {item.title === "Resources" && (
                                    <img
                                      src={TrainingHighlight}
                                      alt="Training section"
                                    />
                                  )}
                                </Styled.DropdownSection>
                              </Styled.DropdownNavItemDetails>
                            </div>
                            <Styled.DropdownColumn>
                              {item.secondary.map((item) => {
                                return (
                                  <DynamicNavLink key={item.title} item={item}>
                                    {item.icon && (
                                      <Styled.DropdownItemIcon>
                                        <item.icon />
                                      </Styled.DropdownItemIcon>
                                    )}
                                    <Styled.DropdownItemTitle>
                                      {item.title}
                                      {item.external && <Styled.ExternalLink />}
                                    </Styled.DropdownItemTitle>
                                    <Styled.DropdownItemDescription>
                                      {item.description}
                                    </Styled.DropdownItemDescription>
                                  </DynamicNavLink>
                                );
                              })}
                            </Styled.DropdownColumn>
                          </Styled.Dropdown>
                        </Styled.HeaderNavItem>
                      );
                    }
                    return (
                      <Styled.HeaderNavItem
                        key={item.title}
                        as={Link}
                        to={item.to || ""}
                        data-testid={`${toCamelCase(item.title)}NavItem`}
                      >
                        <strong>{item.title}</strong>
                      </Styled.HeaderNavItem>
                    );
                  })}
                </>
              )}

              <Button label="Contact us" internalLink="/contact" inverse />
            </>
          ) : null}
          {isAuthenticated && user && (
            <Styled.UserWidget
              onClick={(e) => {
                e.stopPropagation();
                toggleDropdown("userWidget");
              }}
              data-testid="userWidget"
              ref={(el) => addElement(el)}
            >
              <Styled.UserProfile />
              <Styled.UserDropdown
                open={dropdownOpen.userWidget}
                direction="column"
              >
                <Styled.DropdownSection $withBackground>
                  <Styled.DropdownHeading>Logged in as:</Styled.DropdownHeading>
                  <strong>{user.name}</strong>
                  <Styled.DropdownLightText>
                    {user.email}
                  </Styled.DropdownLightText>
                  <Button
                    label="Edit profile"
                    type="button"
                    variant="tertiary"
                    onClick={() => setModalOpen(true)}
                  />
                </Styled.DropdownSection>
                {isAdminUser && (
                  <Styled.UserDropdownItem
                    as={Link}
                    to={isAdmin ? "/" : "/admin"}
                    data-testid="viewAdminPortalButton"
                  >
                    {isAdmin ? "View customer hub" : "View admin portal"}
                  </Styled.UserDropdownItem>
                )}
                <Styled.UserDropdownItem
                  onClick={() =>
                    logout({
                      logoutParams: { returnTo: window.location.origin },
                    })
                  }
                >
                  Logout
                </Styled.UserDropdownItem>
              </Styled.UserDropdown>
            </Styled.UserWidget>
          )}
        </Styled.HeaderUtilitySection>
      ) : (
        <Styled.MobileNav
          onClick={() => {
            toggleDropdown("mobileNav");
          }}
          ref={(el) => addElement(el)}
        >
          <ExpandedMobileNav
            isAdmin={isAdmin}
            openNav={dropdownOpen.mobileNav}
            closeNav={() =>
              setDropdownOpen((prev) => ({
                ...prev,
                mobileNav: false,
              }))
            }
            openEditModal={() => setModalOpen(true)}
          />
        </Styled.MobileNav>
      )}
      {isAuthenticated && (
        <EditProfile
          modalActive={modalOpen}
          toggleModal={() => setModalOpen((prev) => !prev)}
        />
      )}
    </Styled.ExpandedHeader>
  );
};

export default ExpandedHeader;
