import styled, { css } from "styled-components";
import HamburgerIcon from "@assets/icons/nav.svg?react";
import ExternalLinkIcon from "@assets/icons/external-link.svg?react";

export const ExpandedMobileNav = styled.div``;

export const HamburgerMenu = styled(HamburgerIcon)`
  width: 1.875rem;
  height: 1.563rem;
  margin-left: 0.5rem;
  color: ${(props) => props.theme.colors.lightest};
  display: inline-block;
  cursor: pointer;
`;

export const ExternalLink = styled(ExternalLinkIcon)`
  width: 0.625rem;
  margin-left: 0.35rem;
  display: inline-block;
  cursor: pointer;
`;

export const NavActions = styled.div`
  width: 100%;
  padding: 1rem 0.875rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.colors.light};
`;

export const BackButton = styled.button`
  cursor: pointer;
  display: flex;
  padding: 0;
  align-items: center;
  max-width: max-content;
  font-weight: 400;
  color: ${(props) => props.theme.colors.accent1};
  border: none;
  background: none;
  text-decoration: none;
  font-size: 1rem;
  line-height: 1rem;
  svg {
    width: 0.8rem;
    color: ${(props) => props.theme.colors.accent1};
    margin-right: 0.8rem;
  }
`;

export const CloseSection = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
  svg {
    cursor: pointer;
    width: 1.125rem;
  }
`;

export const Dropdown = styled.div<{
  open: boolean;
  placement?: string;
  secondaryMenu?: boolean;
}>`
  cursor: auto;
  display: ${(props) => (props.open ? "flex" : "none")};
  position: absolute;
  top: 0;
  right: -5px;
  padding-bottom: ${(props) => (props.secondaryMenu ? ".5rem" : "0")};
  background: ${(props) => props.theme.colors.lightest};
  color: ${(props) => props.theme.colors.dark};
  flex-direction: column;
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.shadows.medium};
  width: 380px;
  z-index: 7;
`;

export const DropdownSection = styled.div<{ $withBackground?: boolean }>`
  padding: 0.875rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  ${(props) =>
    props.$withBackground &&
    css`
      background: ${props.theme.gradients.light2};
    `}
`;

export const DropdownSubMenuHeading = styled.p`
  margin: 0;
  font-weight: 300;
  line-height: 1.25rem;

  span {
    font-weight: 500;
    display: block;
    margin-bottom: 0.25rem;
  }
`;

export const DropdownHeading = styled.span`
  display: block;
  font-size: 0.875rem;
  line-spacing: 1;
  color: ${(props) => props.theme.colors.accent1};
`;

export const DropdownLightText = styled.span`
  display: block;
  font-weight: 300;
`;

export const DropdownItem = styled.div<{
  submenu?: boolean;
}>`
  display: flex;
  align-items: center;
  ${(props) =>
    props.submenu &&
    css`
      justify-content: space-between;
    `}
  padding: 1rem 0.875rem;
  cursor: pointer;
  border-bottom: 1px solid ${(props) => props.theme.colors.light};

  &:last-child {
    border: none;
    border-radius: 0 0 8px 8px;
  }

  &:hover {
    background: ${(props) => props.theme.colors.light};
  }

  svg {
    margin-right: 0.625rem;
  }
`;

export const NavItemTitle = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LogoutSection = styled.div`
  padding: 0.875rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
