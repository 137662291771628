import { useParams } from "react-router-dom";
import * as Styled from "./styled";
import Page from "@/components/layout/Page";
import useApi from "@/contexts/ApiContext";
import { useQuery } from "@tanstack/react-query";
import LoadingScreen from "@/components/shared-ui/LoadingScreen";
import Chevron from "@assets/icons/arrow-down.svg?react";
import BlockRenderer from "@/components/shared-ui/BlockRenderer";
import DocLayout from "@/components/layout/DocLayout";
import { Download } from "@/components/layout/DocLayout/styled";
import FileDownloadButton from "@/components/utility/FileDownloadButton";
import NotFound from "../NotFound";

const ProjectSummary = () => {
  const api = useApi();
  const { docSlug, sectionSlug } = useParams();
  const { data } = useQuery(
    ["get-project-summary-docs"],
    () => api.projectSummary.getAll()
  );

  let currentDoc;
  let currentSection;

  if (docSlug) {
    currentDoc = data?.project_summary?.find((doc) => doc.slug === docSlug);
  } else {
    currentDoc = data?.project_summary?.[0];
  }

  if (data && data.project_summary.length === 0) {
    return <NotFound />;
  }

  if (!data || !currentDoc) {
    return <LoadingScreen />;
  }

  if (sectionSlug) {
    currentSection = currentDoc?.content_sections.find(
      (section) => section.slug === sectionSlug
    );
  } else {
    currentSection = currentDoc.content_sections[0];
  }

  const docsMenu = data.project_summary.map((doc) => (
    <Styled.SidebarSection key={doc.id} $active={currentDoc.id === doc.id}>
      <Styled.SidebarHeadingLink
        to={`/project-summary/${doc.slug}/${doc.content_sections[0].slug}`}
      >
        <Chevron />
        <Styled.SidebarHeading>
          {doc.title}
          {doc.content_sections.some((section) => section.new) && (
            <Styled.NewTag>New</Styled.NewTag>
          )}
        </Styled.SidebarHeading>
      </Styled.SidebarHeadingLink>
      <Styled.SidebarSubSections>
        {doc.content_sections.map((section) => {
          return (
            <Styled.SidebarItem
              key={section.slug}
              $active={
                currentDoc.id === doc.id &&
                currentSection?.title === section.title
              }
            >
              <Styled.SidebarLink
                to={`/project-summary/${doc.slug}/${section.slug}`}
              >
                {section.title}{" "}
                {section.new && <Styled.NewTag>New</Styled.NewTag>}
              </Styled.SidebarLink>
            </Styled.SidebarItem>
          );
        })}
      </Styled.SidebarSubSections>
    </Styled.SidebarSection>
  ));

  const docContent = (
    <>{currentSection && <BlockRenderer blocks={currentSection.content} />} </>
  );

  return (
    <Page
      title="Project Summary"
      noPadding
      fullWidth
      stickyBreadcrumbs
      whiteBackground
    >
      <DocLayout
        primarySidebar={{
          visible: true,
          menu: docsMenu,
          accordionHeading: currentDoc.title,
        }}
        secondarySidebar={{
          visible: data.project_summary_document ? true : false,
          heading: "Download",
          menu: (
            <Download>
              <FileDownloadButton
                fileObject={data.project_summary_document}
                makeParentClickable
              />
              Full project summary
            </Download>
          ),
        }}
        docHeading={currentSection ? currentSection.title : currentDoc.title}
        docContent={docContent}
      />
    </Page>
  );
};

export default ProjectSummary;
