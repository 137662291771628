import { Link, useLocation } from "react-router-dom";
import * as Styled from "./styled";
import ArrowRight from "@assets/icons/arrow-right.svg?react";

export type ButtonVariant = "secondary" | "tertiary";

type Props = {
  label: string;
  variant?: ButtonVariant;
  inverse?: boolean;
  internalLink?: string;
  centered?: boolean;
  noArrow?: boolean;
  noWrap?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({ label, internalLink, noArrow, ...props }: Props) => {
  const location = useLocation();
  const buttonCode = (
    <Styled.Button {...props}>
      <>
        {label} {!noArrow && <ArrowRight />}
      </>
    </Styled.Button>
  );
  if (internalLink) {
    return (
      <Link to={internalLink} state={{ prevLocation: location.pathname }}>
        {buttonCode}
      </Link>
    );
  }
  return buttonCode;
};

export default Button;
