export const sanitizeHtmlOptions = {
  allowedAttributes: {
    p: ["style"],
    table: ["border"],
    td: ["style"],
    tr: ["style"],
  },
  allowedStyles: {
    "*": {
      // Match HEX and RGB
      color: [
        /^#(0x)?[0-9a-f]+$/i,
        /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/,
      ],
      "text-align": [/^left$/, /^right$/, /^center$/],
      // Match any number with px, em, or %
      "font-size": [/^\d+(?:px|em|%)$/],
      "vertical-align": [/^top$/, /^bottom$/, /^center$/],
    },
  },
};
