import React from "react";
import * as Styled from "./styled";
import Page from "@/components/layout/Page";
import Card from "@/components/shared-ui/Card";
import useApi from "@/contexts/ApiContext";
import useCompany from "@/contexts/CompanyContext";
import { useQuery } from "@tanstack/react-query";
import LoadingScreen from "@/components/shared-ui/LoadingScreen";
import config from "@/config";
import ContactCard from "@/components/shared-ui/ContactCard";

const Configuration = () => {
  const api = useApi();
  const { logo, company_name } = useCompany();

  const { data: companyConfig } = useQuery(["get-company-config"], () =>
    api.company.getConfig()
  );

  if (!companyConfig) {
    return <LoadingScreen />;
  }

  const { configData } = companyConfig;

  const fields = Object.entries(configData).map(([k, v]) => ({
    label: k,
    value: v,
  }));

  const featuredFields = [
    "Name",
    "Firm ID",
    "FCA number",
    "Reg status",
    "Distribution",
    "id",
    "Seccl contact"
  ];
  const regularFields = fields.filter(
    (item) => !featuredFields.includes(item.label)
  );

  return (
    <Page title="Configuration">
      <Styled.Configuration>
        <Card>
          <Styled.PageHeading>Configuration</Styled.PageHeading>
          <Styled.SectionHeading>Company summary</Styled.SectionHeading>
          <Styled.CompanySummaryWrapper>
            <Card>
              <Styled.CompanySummaryCard>
                {logo ? (
                  <Styled.Logo src={`${config.DIRECTUS_URL}/assets/${logo}`} />
                ) : (
                  <h2>{company_name}</h2>
                )}
              </Styled.CompanySummaryCard>
            </Card>
            <Card featureBackground={{ full: true, gradient: "light2" }}>
              <Styled.CompanySummaryCard>
                {fields
                  .filter((item) =>
                    ["Name", "Firm ID", "FCA number"].includes(item.label)
                  )
                  .map((field) => (
                    <FeatureField
                      key={field.label}
                      label={field.label}
                      value={field.value}
                    />
                  ))}
              </Styled.CompanySummaryCard>
            </Card>
            <Card featureBackground={{ full: true, gradient: "light2" }}>
              <Styled.CompanySummaryCard>
                {fields
                  .filter((item) =>
                    ["Reg status", "Distribution"].includes(item.label)
                  )
                  .map((field) => (
                    <FeatureField
                      key={field.label}
                      label={field.label}
                      value={field.value}
                    />
                  ))}
              </Styled.CompanySummaryCard>
            </Card>
          </Styled.CompanySummaryWrapper>
          <Styled.ServiceSummaryContent>
            {regularFields.map((item) => (
              <React.Fragment key={item.label}>
                <Styled.Detail>{item.label}:</Styled.Detail>
                {Array.isArray(item.value) ? (
                  <Styled.FieldValuesWrapper>
                    {item.value.map((v) => (
                      <Styled.FieldValue key={v}>{v}</Styled.FieldValue>
                    ))}
                  </Styled.FieldValuesWrapper>
                ) : (
                  <Styled.FieldValue>{item.value}</Styled.FieldValue>
                )}
              </React.Fragment>
            ))}
          </Styled.ServiceSummaryContent>
        </Card>

        <ContactCard />
      </Styled.Configuration>
    </Page>
  );
};

export default Configuration;

const FeatureField = ({
  label,
  value,
}: {
  label: string;
  value: string | string[];
}) => {
  return (
    <Styled.DetailItem>
      <span>{label}:</span>
      <Styled.Detail>
        {Array.isArray(value)
          ? value.map((v, i) => (
              <React.Fragment key={i}>{`${v}${
                i + 1 != value.length ? ", " : ""
              }`}</React.Fragment>
            ))
          : value}
      </Styled.Detail>
    </Styled.DetailItem>
  );
};
