import { Link } from "react-router-dom";
import styled from "styled-components";

export const SidebarHeadingLink = styled(Link)`
  display: flex;
  align-items: flex-start;
  svg {
    margin-top: 0.35rem;
    width: 0.7rem;
    margin-right: 0.7rem;
    color: ${(props) => props.theme.colors.accent1};
  }
`;

export const SidebarHeading = styled.h2`
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.25rem;
  cursor: pointer;
  font-weight: 300;
  &:hover {
    color: ${(props) => props.theme.colors.accent1};
  }
`;

export const SidebarSubSections = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  padding-left: 1.4rem;
`;

export const SidebarItem = styled.li<{ $active?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  padding-left: 1.2rem;
  padding-top: 0.2rem;
  line-height: 1.25rem;
  font-weight: 300;
  ${(props) =>
    props.$active &&
    `
    font-weight: normal;
    `};
  &:hover {
    color: ${(props) => props.theme.colors.accent1};
  }
  &:before {
    content: "—";
    flex-shrink: 0;
    color: ${(props) =>
      props.$active ? props.theme.colors.accent1 : "lightgray"};
    font-weight: bold;
    margin-left: -1em;
    padding-right: 0.8rem;
    padding-top: 0.5rem;
  }
`;

export const SidebarLink = styled(Link)`
  display: inline-block;
  padding-top: 0.5rem;
`;

export const SidebarSection = styled.div<{ $active?: boolean }>`
  padding-bottom: 1rem;
  ${SidebarHeading} {
    ${(props) =>
      props.$active &&
      `
      font-weight: bold;
      `};
  }
  ${SidebarItem} {
    display: ${(props) => (props.$active ? "flex" : "none")};
  }
  svg {
    ${(props) =>
      !props.$active &&
      `
      transform: rotate(270deg);
      `};
  }
`;

export const AnchorItem = styled.a<{ $active?: boolean }>`
  display: block;
  padding-bottom: 0.75rem;
  font-weight: ${(props) => (props.$active ? "normal" : "300")};
  line-height: 1.2rem;
  &:last-of-type {
    padding: 0;
  }
`;

export const NewTag = styled.div`
  position: relative;
  display: inline;
  margin-left: 0.4rem;
  color: ${(props) => props.theme.colors.lightest};
  line-height: 1;
  font-weight: 300;
  font-size: 0.8rem;
  padding: 0.1rem 0.25rem 0.1rem 0.7rem;
  z-index: 1;
  white-space: nowrap;

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    clip-path: polygon(
      0 47%,
      0 53%,
      17% 95%,
      23% 100%,
      100% 100%,
      100% 0,
      23% 0,
      17% 5%
    );
    background: ${(props) => props.theme.colors.accent1};
    border-radius: 0.25rem;
  }
`;
