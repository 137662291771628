import type { Company } from "@/services/api/types";
import {
  type CompanyStatus,
  type NavMenu,
  type UserRoles,
} from "./nav.constants";

const shouldHideNavItem = (
  userRoles: string[],
  roleGuard?: UserRoles,
  statusGuard?: CompanyStatus,
  companyStatus?: string
): boolean => {
  return !!(
    (statusGuard && statusGuard !== companyStatus) ||
    (roleGuard && !userRoles.includes(roleGuard))
  );
};

const updateNavItems = (
  item: NavMenu,
  links: Company["companySpecificLinks"],
  projectSummaryLink: string | null,
  userRoles: string[],
  companyStatus: string
): NavMenu => {
  const updatedItem = { ...item };

  // Replace link if there's a matching airtableLabel
  if (updatedItem.airtableLabel && links[updatedItem.airtableLabel as keyof typeof links]) {
    updatedItem.to = links[updatedItem.airtableLabel as keyof typeof links];
  }

  // Set project summary link if applicable
  if (projectSummaryLink && updatedItem.title === "Project summary") {
    updatedItem.to = projectSummaryLink;
  }

  // Determine if the item should be hidden based on roleGuard or statusGuard
  if (shouldHideNavItem(userRoles, updatedItem.roleGuard, updatedItem.statusGuard, companyStatus)) {
    return { ...updatedItem, hidden: true };
  }

  return updatedItem;
};

export const addCustomerSpecificLinks = (
  links: Company["companySpecificLinks"],
  navMenu: ReadonlyArray<NavMenu>,
  projectSummaryLink: string | null,
  userRoles: string[],
  companyStatus: string
): NavMenu[] => {
  return navMenu.reduce<NavMenu[]>((acc, item) => {
    const updatedItem = updateNavItems(item, links, projectSummaryLink, userRoles, companyStatus);

    // Exclude hidden items immediately
    if (updatedItem.hidden) return acc;

    // Process secondary menu items recursively
    const updatedSecondary = updatedItem.secondary
      ?.map((secItem) => updateNavItems(secItem, links, projectSummaryLink, userRoles, companyStatus))
      .filter((secItem) => !secItem.hidden) as NavMenu[];

    if (updatedSecondary?.length) {
      updatedItem.secondary = updatedSecondary;
    }

    acc.push(updatedItem);
    return acc;
  }, []);
};
