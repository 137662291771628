import Page from "@/components/layout/Page";
import * as Styled from "./styled";
import Card from "@/components/shared-ui/Card";
import { CardHeading } from "../AdminDashboard/styled";
import useApi from "@/contexts/ApiContext";
import { useMutation, useQuery } from "@tanstack/react-query";
import LoadingScreen from "@/components/shared-ui/LoadingScreen";
import Select from "@/components/shared-ui/Select";
import { useState } from "react";
import ActionMenu from "@/components/shared-ui/ActionMenu";
import EditIcon from "@assets/icons/admin_edit_icon.svg?react";
import DeleteIcon from "@assets/icons/admin_trash_icon.svg?react";
import { useNavigate } from "react-router-dom";
import Button from "@/components/shared-ui/Button";
import Modal from "@/components/shared-ui/Modal";
import ArrowRight from "@assets/icons/arrow-right.svg?react";
import ArrowLeft from "@assets/icons/arrow-left.svg?react";
import { useDebounce } from "@/hooks/useDebounce";
import Input from "@/components/shared-ui/Input";
import AdminUserTable from "./AdminUserTable";
import { sortCompanies } from "../admin.utils";

const AdminUserManagement = () => {
  const api = useApi();
  const navigate = useNavigate();
  const [companyFilter, setCompanyFilter] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [submittedSearch, setSubmittedSearch] = useState<string>("");
  const [modalOpen, setModalOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [userForDeletion, setUserForDeletion] = useState<{
    id: string;
    name: string;
  }>();
  const [page, setPage] = useState(1);
  useDebounce(
    () => {
      setPage(1);
      setSubmittedSearch(searchValue);
    },
    [searchValue],
    500
  );

  const { data: users, refetch } = useQuery(
    ["get-users", companyFilter, page, submittedSearch],
    () => api.admin.user.getAll(page, companyFilter, submittedSearch),
    { keepPreviousData: true }
  );
  const { data: companies } = useQuery(["get-companies"], () =>
    api.admin.company.getAll()
  );

  const deleteUser = useMutation({
    mutationFn: api.admin.user.delete,
    onSettled: () => {
      setSubmitted(true);
    },
  });

  if (!users || !companies) {
    return <LoadingScreen isAdmin />;
  }

  const dataForTable = users.users.map(
    ({ name, user_id, email, app_metadata }) => {
      const companyName = companies.find(
        (company) => company.id == app_metadata.companyId
      )?.company_name;
      return {
        Name: name,
        Email: email,
        Company: companyName,
        Actions: (
          <ActionMenu
            menuItems={[
              {
                label: "Edit",
                Icon: EditIcon,
                onClick: () => navigate(`/admin/users/edit/${user_id}`),
                testId: "editUserButton",
              },
              {
                label: "Delete",
                Icon: DeleteIcon,
                onClick: () => {
                  setUserForDeletion({ id: user_id, name: name });
                  setModalOpen(true);
                },
              },
            ]}
          />
        ),
      };
    }
  );

  const filterOptions = sortCompanies(companies);
  filterOptions.unshift({ id: "", label: "All" });

  return (
    <Page title="User management" isAdmin>
      <Styled.AdminUserManagement>
        <Card>
          <Styled.HeadingWrapper>
            <div>
              <CardHeading>User management</CardHeading>
              <p>Welcome to the user management section.</p>
            </div>
            <Button
              label="Create new"
              internalLink="/admin/users/create"
              data-testid="createNewButton"
            />
          </Styled.HeadingWrapper>
          <Styled.TableFilters>
            <Input
              placeholder="Search"
              id="search"
              name="search"
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              altVariant
              data-testid="searchInput"
            />
            <Select
              id="companyfilter"
              name="companyFilter"
              label="Filter by company:"
              value={companyFilter}
              onChange={(e) => {
                setPage(1);
                setCompanyFilter(e.target.value);
              }}
              options={filterOptions}
              lightVariant
              compact
            />
          </Styled.TableFilters>
          <Styled.TableWrapper>
            {dataForTable.length ? (
              <AdminUserTable data={dataForTable} />
            ) : (
              <Styled.NoResults data-testid="noResults">
                No results
              </Styled.NoResults>
            )}
          </Styled.TableWrapper>
          <Styled.PaginationWrapper>
            {users.start !== 0 && (
              <Styled.PaginationButton
                $col={1}
                onClick={() => setPage((prev) => prev - 1)}
                data-testid="prevPageButton"
              >
                <ArrowLeft />
              </Styled.PaginationButton>
            )}
            {users.start + users.limit < users.total && (
              <Styled.PaginationButton
                $col={2}
                onClick={() => setPage((prev) => prev + 1)}
                data-testid="nextPageButton"
              >
                <ArrowRight />
              </Styled.PaginationButton>
            )}
          </Styled.PaginationWrapper>
        </Card>
      </Styled.AdminUserManagement>
      <Modal
        modalActive={modalOpen && !submitted}
        toggleModal={() => setModalOpen((prev) => !prev)}
        title="Are you sure?"
        actionButton={{
          label: "Delete user",
          onClick: () =>
            userForDeletion && deleteUser.mutate(userForDeletion.id),
          "data-testid": "confirmationModalButton",
        }}
        cancelButton={{
          label: "Cancel",
          onClick: () => {
            setUserForDeletion(undefined);
            setModalOpen(false);
          },
        }}
      >
        <p>
          This will delete the user <strong>{userForDeletion?.name}</strong>.
        </p>
        This is an irreversible action.
      </Modal>
      <Modal
        modalActive={modalOpen && submitted}
        toggleModal={() => setModalOpen((prev) => !prev)}
        title={deleteUser.isSuccess ? "Successfully deleted" : "Error"}
        cancelButton={{
          label: "Close",
          onClick: () => {
            setUserForDeletion(undefined);
            setSubmitted(false);
            setModalOpen(false);
            if (deleteUser.isSuccess) {
              refetch();
            }
          },
          "data-testid": "completionModalButton",
        }}
        disableOutsideClick={deleteUser.isSuccess}
      >
        {deleteUser.isSuccess && "The user has been successfully deleted."}
        {deleteUser.isError && "Sorry there was an issue deleting the user."}
      </Modal>
    </Page>
  );
};

export default AdminUserManagement;
