import ContactCard from "@/components/shared-ui/ContactCard";
import * as Styled from "./styled";
import Card from "@/components/shared-ui/Card";
import Page from "@/components/layout/Page";
import EscalationPoint from "./EscalationPoint";
import { useQuery } from "@tanstack/react-query";
import useApi from "@/contexts/ApiContext";
import LoadingScreen from "@/components/shared-ui/LoadingScreen";

const AccountManagement = () => {
  const api = useApi();
  const { data: companyConfig } = useQuery(["get-company-config"], () =>
    api.company.getConfig()
  );

  if (!companyConfig) {
    return <LoadingScreen />;
  }

  const { configData, accountManagementData } = companyConfig;
  return (
    <Page title="Account management">
      <Styled.AccountManagement>
        {accountManagementData && (
          <Card>
            <Styled.PageHeading>
              Account management structure
            </Styled.PageHeading>
            <Styled.ServiceSummaryContent>
              {configData.Status && (
                <>
                  <Styled.Detail>Status:</Styled.Detail>
                  <Styled.HighlightedFieldValue isStatus>
                    {configData.Status}
                  </Styled.HighlightedFieldValue>
                </>
              )}
              {accountManagementData["Account manager"] && (
                <>
                  <Styled.Detail>Account manager:</Styled.Detail>
                  <Styled.FieldValuesWrapper>
                    {accountManagementData["Account manager"]}
                  </Styled.FieldValuesWrapper>
                </>
              )}
              {accountManagementData["Customer success exec"] && (
                <>
                  <Styled.Detail>Customer success exec:</Styled.Detail>
                  <Styled.FieldValuesWrapper>
                    {accountManagementData["Customer success exec"].map((v) => (
                      <Styled.FieldValue key={v}>{v}</Styled.FieldValue>
                    ))}
                  </Styled.FieldValuesWrapper>
                </>
              )}
              {accountManagementData.escalationPoint && (
                <>
                  <Styled.Detail>Escalation point:</Styled.Detail>
                  <Styled.FieldValuesWrapper isEscalationPoint>
                    {accountManagementData.escalationPoint.map((contact) => {
                      return (
                        <EscalationPoint key={contact.Name} fields={contact} />
                      );
                    })}
                  </Styled.FieldValuesWrapper>
                </>
              )}
              {accountManagementData.Meetings && (
                <>
                  <Styled.Detail>Meetings:</Styled.Detail>
                  <Styled.FieldValuesWrapper>
                    {accountManagementData.Meetings.map((v) => (
                      <Styled.HighlightedFieldValue key={v}>
                        {v}
                      </Styled.HighlightedFieldValue>
                    ))}
                  </Styled.FieldValuesWrapper>
                </>
              )}
            </Styled.ServiceSummaryContent>
          </Card>
        )}
        <ContactCard />
      </Styled.AccountManagement>
    </Page>
  );
};

export default AccountManagement;
