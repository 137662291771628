import { useQuery } from "@tanstack/react-query";
import * as Styled from "./styled";
import useApi from "@/contexts/ApiContext";
import LoadingScreen from "@/components/shared-ui/LoadingScreen";
import { type Roadmap, type RoadmapItem } from "@/services/api/types";
import ContactCard from "@/components/shared-ui/ContactCard";
import Page from "@/components/layout/Page";
import RoadmapHeader from "./RoadmapHeader";
import ItemCard from "./ItemCard";
import RoadmapIntroduction from "./RoadmapIntroduction";
import { useEffect, useState } from "react";
import DetailsModal from "./DetailsModal";
import { Link, useLocation } from "react-router-dom";

const ProductRoadmap = () => {
  const api = useApi();
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState<RoadmapItem | undefined>();
  const [modalOpen, setModalOpen] = useState(false);
  const [updateLink, setUpdateLink] = useState<string>("");

  const { data: roadmapData } = useQuery(["get-roadmap"], () =>
    api.roadmap.getAll()
  );

  const { data: roadmapUpdate } = useQuery(["get-latest-roadmap-update"], () =>
    api.update.getLatestRoadmapUpdate()
  );

  useEffect(() => {
    if (roadmapUpdate && roadmapUpdate.length) {
      setUpdateLink(`/updates/${roadmapUpdate[0].id.toString()}`);
    }
  }, [roadmapUpdate, setUpdateLink]);

  if (!roadmapData || !roadmapUpdate) {
    return <LoadingScreen withoutPage />;
  }

  // Group data by Product Area field
  const groupedByProductArea = roadmapData.reduce(
    (accumulator, currentValue) => {
      (accumulator[currentValue["product_area"]] =
        accumulator[currentValue["product_area"]] || []).push(currentValue);
      return accumulator;
    },
    {} as { [key: string]: Roadmap[] }
  );

  const handleSelect = (item: RoadmapItem) => {
    setSelectedItem(item);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen((prev) => !prev);
    setSelectedItem(undefined);
  };

  return (
    <Page whiteBackground title="Product roadmap">
      <Styled.ProductRoadmap>
        <RoadmapIntroduction
          updateLink={updateLink}
          title={roadmapUpdate[0].title || "Roadmap review"}
        />
        <Styled.RoadmapContainer>
          {Object.entries(groupedByProductArea).map((area) => {
            return (
              <div key={area[0]}>
                <RoadmapHeader productArea={area[0]} />
                {area[1].map((item, i) => {
                  return (
                    <Styled.Roadmap
                      noBorder={i === area[1].length - 1}
                      key={`${item.capability}-row`}
                    >
                      <Styled.ProductRowHeader>
                        {item.capability}
                      </Styled.ProductRowHeader>
                      <Styled.RoadmapCardColumn>
                        {item.now && (
                          <ItemCard
                            item={item.now}
                            onClick={() => handleSelect(item.now)}
                          />
                        )}
                      </Styled.RoadmapCardColumn>
                      <Styled.RoadmapCardColumn>
                        {item.next && (
                          <ItemCard
                            item={item.next}
                            onClick={() => handleSelect(item.next)}
                          />
                        )}
                      </Styled.RoadmapCardColumn>
                      <Styled.RoadmapCardColumn>
                        {item.later && (
                          <ItemCard
                            item={item.later}
                            isLater
                            onClick={() => handleSelect(item.later)}
                          />
                        )}
                      </Styled.RoadmapCardColumn>
                      <Styled.DoneColumn>
                        {item.done &&
                          item.done.map((done) => {
                            return (
                              <Link
                                to={`/updates/${done.release_note_id}`}
                                state={{ prevLocation: location.pathname }}
                                key={done.item}
                              >
                                {done.item}
                              </Link>
                            );
                          })}
                      </Styled.DoneColumn>
                    </Styled.Roadmap>
                  );
                })}
              </div>
            );
          })}
        </Styled.RoadmapContainer>
        <ContactCard />
      </Styled.ProductRoadmap>
      {selectedItem && (
        <DetailsModal
          item={selectedItem}
          modalActive={modalOpen}
          toggleModal={() => handleClose()}
          updateLink={updateLink}
        />
      )}
    </Page>
  );
};

export default ProductRoadmap;
