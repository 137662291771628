import styled from "styled-components";
import Chevron from "@assets/icons/arrow-down.svg";

export const Select = styled.select<{ $alt?: boolean }>`
  font-size: 1rem;
  line-height: 1.375rem;
  color: inherit;
  background: ${(props) =>
    props.$alt ? props.theme.colors.lightest : props.theme.colors.light};
  border-radius: 0.25rem;
  border: none;
  box-shadow: inset 0 0 0 1px ${(props) => props.theme.colors.dark};
  padding: 0.5rem 0.938rem;
  padding-right: 1.7rem;
  margin: 0;
  outline-color: fade-out(${(props) => props.theme.colors.highlight2}, 0.5);
  transition:
    box-shadow 0.2s,
    outline 0.2s;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(${Chevron});
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px);
  &:focus {
    box-shadow: inset 0 0 0 1px ${(props) => props.theme.colors.highlight2};
  }
  &:disabled {
    color: rgba(29, 23, 57, 0.5);
    box-shadow: none;
  }
`;

export const Wrapper = styled.div<{ $compact?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: ${(props) => (props.$compact ? "row" : "column")};
  align-items: ${(props) => (props.$compact ? "center" : "flex-start")};
  padding-bottom: ${(props) => (props.$compact ? "0" : "1rem")};
`;

export const Label = styled.label<{ $compact?: boolean }>`
  font-weight: 700;
  flex-shrink: 0;
  padding-bottom: ${(props) => (props.$compact ? "0" : "0.3rem")};
  padding-right: ${(props) => (props.$compact ? "0.5rem" : "0")};
`;
