import * as Styled from "./styled";
import { Link } from "react-router-dom";
import type { SearchIndexTypes } from "@/components/shared-ui/Search/Search";
import useCompany from "@/contexts/CompanyContext";
import CompactHeader from "./CompactHeader";
import ExpandedHeader from "./ExpandedHeader";
import { Logo } from "./styled";

type Props = {
  isAdmin?: boolean;
  searchIndexPriority?: SearchIndexTypes;
};

const Header = ({ isAdmin, searchIndexPriority }: Props) => {
  const { status } = useCompany();

  const isCustomer = status === "Live" || status === "Onboarding";

  return (
    <Styled.Header $alt={isAdmin} data-testid="headerContainer">
      <Styled.HeaderContent>
        <Link to="/">
          <Logo data-testid="secclLogo" />
        </Link>
        {isCustomer ? (
          <ExpandedHeader
            isAdmin={isAdmin}
            searchIndexPriority={searchIndexPriority}
          />
        ) : (
          <CompactHeader
            isAdmin={isAdmin}
            searchIndexPriority={searchIndexPriority}
          />
        )}
      </Styled.HeaderContent>
    </Styled.Header>
  );
};

export default Header;
