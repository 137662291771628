import { Link } from "react-router-dom";
import * as Styled from "./styled";
import type { NavMenu } from "../../shared/nav.constants";

type Props = {
  item: NavMenu;
  children: React.ReactNode;
};

const DynamicNavLink = ({ item, children }: Props) => {
  if (item.hidden || !item.to)
    return null;

  if (item.external) {
    return (
      <Styled.DropdownItem
        as="a"
        href={item.to}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </Styled.DropdownItem>
    );
  }

  return (
    <Styled.DropdownItem as={Link} to={item.to}>
      {children}
    </Styled.DropdownItem>
  );
};

export default DynamicNavLink;
