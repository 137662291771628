import * as Styled from "../styled";
import FlexContainer from "@components/shared-ui/FlexContainer";
import Illustration from "@assets/dashboard-illustration.svg";

type Props = {
  companyName: string;
};

const SalesWelcomeCard = ({ companyName }: Props) => {
  return (
    <Styled.WelcomeCardOuter>
      <FlexContainer display="flex">
        <div>
          <h1 data-testid="companyName">
            {companyName} & Seccl:
            <Styled.AccentHeading>your customer hub</Styled.AccentHeading>
          </h1>
          <p>
            Welcome to your customer hub – home to the key resources you’ll need
            to launch, operate and scale your platform on Seccl.
          </p>
        </div>
      </FlexContainer>
      <img src={Illustration} alt="" />
    </Styled.WelcomeCardOuter>
  );
};

export default SalesWelcomeCard;
