// These are the variables we use in the front-end.
// Accessed via import.meta.env because that is how Vite exposes them.
// They must be prefixed with VITE_ to be accessible.
const config = {
  NETLIFY_CONTEXT: import.meta.env.VITE_NETLIFY_CONTEXT!,
  DIRECTUS_URL: import.meta.env.VITE_DIRECTUS_URL!,
  AUTH0_DOMAIN: import.meta.env.VITE_AUTH0_DOMAIN!,
  AUTH0_AUDIENCE: import.meta.env.VITE_AUTH0_AUDIENCE!,
  AUTH0_CUSTOMER_HUB_CLIENT_ID: import.meta.env
    .VITE_AUTH0_CUSTOMER_HUB_CLIENT_ID!,
  AUTH0_REDIRECT_URL: import.meta.env.VITE_AUTH0_REDIRECT_URL!,
  ALGOLIA_APP_ID: import.meta.env.VITE_ALGOLIA_APP_ID!,
  ALGOLIA_SEARCH_API_KEY: import.meta.env.VITE_ALGOLIA_SEARCH_API_KEY!,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN!,
};

export default config;
