import type { RoadmapItem } from "@/services/api/types";
import * as Styled from "./styled";
import ExclamationCircle from "@assets/icons/icon-changed.svg?react";
import MovedForward from "@assets/icons/icon-progressed.svg?react";
import PushedBack from "@assets/icons/icon-moved-back.svg?react";

type Props = {
  item: RoadmapItem;
  isLater?: boolean;
  onClick: () => void;
};

const itemProgressionDict = {
  moved_forward: <MovedForward />,
  pushed_back: <PushedBack />,
};

const ItemCard = ({ item, isLater, onClick }: Props) => {
  return (
    <Styled.ItemCard isLater={isLater} onClick={onClick}>
      <Styled.CardTitleContainer isLater={isLater}>
        {item.roadmap_item}
      </Styled.CardTitleContainer>
      <Styled.ItemStatusContainer>
        <Styled.DeliveryQuarter>
          {item.delivery_quarter && item.delivery_quarter}
        </Styled.DeliveryQuarter>
        <Styled.ProgressionIcons>
          {item.whats_changed_description && <ExclamationCircle />}
          {item.item_progression && itemProgressionDict[item.item_progression]}
        </Styled.ProgressionIcons>
      </Styled.ItemStatusContainer>
    </Styled.ItemCard>
  );
};

export default ItemCard;
