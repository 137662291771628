import * as Styled from "../styled";
import UpdatesCard from "../../UpdatesCard";
import Illustration from "@assets/dashboard-illustration.svg";

type Props = {
  companyName: string;
};

const WelcomeCard = ({ companyName }: Props) => {
  return (
    <Styled.WelcomeCardOuter>
      <Styled.WelcomeCardInner>
        <div>
          <h1 data-testid="companyName">
            {companyName} & Seccl:
            <Styled.AccentHeading>your customer hub</Styled.AccentHeading>
          </h1>
        </div>
        <Styled.WelcomeCardImageSection>
          <p>
            Welcome to your customer hub – home to the key resources you’ll need
            to launch, operate and scale your platform on Seccl.
          </p>
          <img src={Illustration} alt="" />
        </Styled.WelcomeCardImageSection>
      </Styled.WelcomeCardInner>

      <Styled.UpdatesCard>
        <Styled.CardHeading>Important updates</Styled.CardHeading>
        <UpdatesCard />
      </Styled.UpdatesCard>
    </Styled.WelcomeCardOuter>
  );
};

export default WelcomeCard;
